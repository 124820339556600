import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import { auth } from "./auth.module";
import { common } from "./common.module";
import { franchise } from "./franchise.module";
import { franchisee } from "./franchisee.module";
import { policies } from "./policies.module";

Vue.use(Vuex);

export default new Vuex.Store({
  mutations: {
    // RESET_ALL_MODULES(state) {
    //   Object.keys(state).forEach(key => {
    //     if(key == 'franchise') state[key] = {}
    //   })
    // }
  },
  modules: {
    auth,
    common,
    franchise,
    franchisee,
    policies,
  },
  plugins: [createPersistedState()],
});
