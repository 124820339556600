<template>
  <v-card>
    <v-app-bar
      absolute
      height="90"
      bordered
      dense
      elevation="0"
      hide-on-scroll
      scroll-threshold="500"
      app
    >
      <v-app-bar-nav-icon
        class="mr-5"
        @click="toggleSideMenu"
      ></v-app-bar-nav-icon>

      <v-col cols="6">
        <v-toolbar-title>
          <h3>{{ franchiseData.name }}</h3>
        </v-toolbar-title>
      </v-col>

      <v-spacer></v-spacer>

      <template
        v-if="currentUser && currentUser.user_role_name === 'FRANCHISEE'"
      >
        <v-btn text @click="franchiseDetailsDialog = true">
          <v-icon small class="mr-1">mdi-information</v-icon>
          Client Details
        </v-btn>

        <FranchiseDetails
          :dialog="franchiseDetailsDialog"
          @closeDialog="franchiseDetailsDialog = false"
        />
      </template>
      <!-- 
      <v-app-bar-nav-icon>
        <v-icon>mdi-bell</v-icon>
      </v-app-bar-nav-icon>

      <v-app-bar-nav-icon @click="goToProfile()">
        <v-icon>mdi-account</v-icon>
      </v-app-bar-nav-icon> -->

      <!-- <v-app-bar-nav-icon @click="editFranchiseSettings">
        <v-icon>mdi-cog</v-icon>
      </v-app-bar-nav-icon> -->

      <v-app-bar-nav-icon @click="logout" class="mr-12">
        <v-icon>mdi-logout</v-icon>
      </v-app-bar-nav-icon>
    </v-app-bar>
  </v-card>
</template>

<script>
import BaseService from "@/services/base.service";
import FranchiseDetails from "@/components/franchise/FranchiseDetails";
import { bus } from "@/main.js";

export default {
  name: "FranchisorTitleBar",
  data() {
    return {
      franchiseData: {},
      franchiseDetailsDialog: false,
      drawer: false,
    };
  },
  computed: {
    isFranchisor() {
      if (
        this.currentUser &&
        (this.currentUser.user_role_name == "FRANCHISE" ||
          this.currentUser.user_role_name == "FRANCHISEE")
      ) {
        return true;
      } else return false;
    },
  },
  components: {
    FranchiseDetails,
  },
  created() {
    this.getFranchiseData();
  },
  props: {
    currentUser: Object,
  },
  watch: {
    "$route.path"() {
      if (this.$route.path == "/user") {
        this.getFranchiseData();
      }
    },
  },
  methods: {
    goToProfile() {
      this.$router.push("/profile");
    },
    async getFranchiseData() {
      let franchiseId;
      if (this.currentUser.user_role_name == "FRANCHISE") {
        if (this.currentUser.franchise?.id) {
          franchiseId = this.currentUser.franchise.id;
        } else {
          this.$store.dispatch(
            "common/setFlashMsg",
            "Some data related error occurred while logging in. Please contact the system administrator."
          );
          this.$store.dispatch("auth/logout");
        }
      } else if (this.currentUser.user_role_name == "FRANCHISEE") {
        if (this.currentUser.franchisee?.franchise?.id) {
          franchiseId = this.currentUser.franchisee.franchise.id;
        } else {
          this.$store.dispatch(
            "common/setFlashMsg",
            "Some data related error occurred while logging in. Please contact the system administrator."
          );
          this.$store.dispatch("auth/logout");
        }
      }
      let service = new BaseService("franchises");
      let res = await service.get_one(franchiseId);
      if (res) {
        this.franchiseData = res.data;
        this.$store.commit(`franchise/setFranchiseData`, res.data);
      }
    },
    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.go();
    },
    toggleSideMenu() {
      this.drawer = !this.drawer;
      bus.$emit(`toggleSideMenu`, this.drawer);
    },
    editFranchiseSettings() {
      if (this.currentUser.user_role_name == "FRANCHISE") {
        this.$router
          .push({
            path: "/franchise/add",
            query: {
              franchiseSettings: true,
            },
          })
          .catch(() => {});
      } else {
        this.$router
          .push({
            path: "/franchisees/add",
            query: {
              franchiseeSettings: true,
              id: this.currentUser.franchisee.id,
            },
          })
          .catch(() => {});
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
