<template>
  <div>
    <h1 class="page-title">Change Password</h1>

    <div class="card card-container login-card top-margin-card">
      <form name="form" @submit.prevent="changePassword">
        <div class="form-group" v-if="!changeSuccess">
          <label for="password">Enter New Password</label>
          <v-text-field
            v-model.trim="password"
            v-validate="'required'"
            type="password"
            :rules="pwdRule"
            class="form-control"
            name="password"
          ></v-text-field>
        </div>
        <div class="form-group" v-if="!changeSuccess">
          <label for="confirmPassword">Confirm Password</label>
          <v-text-field
            v-model.trim="confirmPassword"
            v-validate="'required'"
            :rules="pwdConfirmRule"
            type="password"
            class="form-control"
            name="confirmPassword"
          ></v-text-field>
        </div>
        <div class="form-group" v-if="!changeSuccess">
          <div class="alert alert-danger" role="alert">
            <p>
              Password must be: <br />
              1. Between 8 and 16 characters<br />
              2. Must contain at least an uppercase, a lowercase, a number and a
              special character.<br />
              3. Not be too similar to the existing one.<br />
              4. Not contain the username<br />
            </p>
          </div>
        </div>
        <div class="form-group" v-if="!changeSuccess">
          <v-btn elevation-2 type="submit" :disabled="!isPasswordMatched">
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span>Reset Password</span>
          </v-btn>
        </div>
        <br />
        <div class="form-group">
          <div v-if="message" class="alert alert-danger" role="alert">
            {{ message }}
          </div>
        </div>
        <br />
        <div class="form-group">
          <div v-if="changeSuccess" class="alert alert-danger" role="alert">
            <router-link :to="{ path: '/Login' }"
              >Please login to continue</router-link
            >
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      loading: false,
      changeSuccess: false,
      password: "",
      confirmPassword: "",
      message: "",
      token: "",
      pwdRule: [
        (v) =>
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/.test(
            v
          ) || "Please enter password in correct format",
      ],
      pwdConfirmRule: [
        (v) => v === this.password || "Passwords are not matching",
      ],
    };
  },
  computed: {
    isPasswordMatched() {
      return this.confirmPassword && this.confirmPassword === this.password;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/profile");
    }
    this.token = this.$route.params.token;
  },
  methods: {
    async changePassword() {
      this.loading = true;
      this.$store
        .dispatch("auth/changePassword", {
          token: this.token,
          password: this.password,
        })
        .then(
          () => {
            this.changeSuccess = true;
            this.loading = false;
            this.message = "Password changed successfully!";
          },
          (error) => {
            this.loading = false;
            this.message =
              (error.response && error.response.data?.msg) ||
              error.message ||
              error.toString();
          }
        );
    },
  },
};
</script>

<style scoped>
label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 450px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 0px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
</style>
