import axios from "axios";
import authHeader from "./auth-header";

class AuthService {
  login(user) {
    return axios
      .post(process.env.VUE_APP_API_BASE_URL + "/login", {
        email: user.email,
        password: user.password,
      })
      .then((response) => {
        let user = response.data;
        if (user.token) {
          //convert roles string into obj
          user.roles = [user.user_role_name];
          localStorage.setItem("user", JSON.stringify(user));
        }
        return user;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(user) {
    return axios.post(process.env.VUE_APP_API_BASE_URL + "/users", {
      email: user.email,
      password_hash: user.password,
    });
  }

  resetPassword(user) {
    return axios
      .post(process.env.VUE_APP_API_BASE_URL + "/password/forgot", {
        email: user.email,
      })
      .then((response) => {
        return response;
      });
  }

  refreshToken(token) {
    return axios
      .post(
        process.env.VUE_APP_API_BASE_URL + "/tokens/refresh",
        {},
        {
          headers: authHeader(token),
        }
      )
      .then(
        (response) => {
          let token = response.data.token;
          if (token) {
            const user = JSON.parse(localStorage.getItem("user"));
            user.token = token;
            localStorage.setItem("user", JSON.stringify(user));
          }
          return response;
        },
        (error) => {
          return error;
        }
      );
  }

  changePassword(token, password) {
    return axios
      .post(process.env.VUE_APP_API_BASE_URL + `/password/reset/${token}`, {
        password,
      })
      .then((response) => {
        return response;
      });
  }

  // stringToArr(str, delimiter){
  //   arr = str.split(delimiter);

  // }
}

export default new AuthService();
