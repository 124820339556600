const initialState = {
  franchiseeData: {},
  selectedSituation: {},
};

export const franchisee = {
  namespaced: true,

  state: initialState,

  mutations: {
    setFranchiseeData(state, franchisee) {
      state.franchiseeData = franchisee;
    },
    setSelectedSituation(state, selectedSituation) {
      state.selectedSituation = selectedSituation;
    },
  },

  getters: {
    getFranchiseeData(state) {
      return state.franchiseeData;
    },

    getSelectedSituation(state) {
      return state.selectedSituation;
    },
  },
};
