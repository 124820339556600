import axios from "axios";
import authHeader from "./auth-header";
import authService from "./auth.service";
import store from "./../store";

export default class BaseService {
  token = null;

  constructor(url_fragment) {
    this.url = process.env.VUE_APP_API_BASE_URL + "/" + url_fragment;
  }

  get_all(filters = {}) {
    try {
      let res = axios.get(this.url, {
        headers: authHeader(this.token),
        params: filters,
      });

      return res;
    } catch (error) {
      return error.response;
    }
  }

  get_one(id) {
    try {
      let res = axios.get(this.url + `/${id}`, { headers: authHeader() });
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async create(obj) {
    try {
      let response = await axios.post(this.url, obj, { headers: authHeader() });
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async update(obj) {
    try {
      let response = await axios.put(this.url + `/${obj.id}`, obj, {
        headers: authHeader(),
      });
      return response;
    } catch (error) {
      return error.response ? error.response : error;
    }
  }

  async patch(obj) {
    try {
      let response = await axios.patch(this.url + `/${obj.id}`, obj, {
        headers: authHeader(),
      });
      return response;
    } catch (error) {
      return error.response ? error.response : error;
    }
  }

  async delete(id) {
    try {
      let response = await axios.delete(this.url + `/${id}`, {
        headers: authHeader(),
      });
      return response;
    } catch (error) {
      return error.response ? error.response : error;
    }
  }

  set token(token) {
    this.token = token;
  }
}

axios.interceptors.response.use(
  function(response) {
    return response;
  },
  async function(error) {
    if (error.response.status === 401) {
      let user = JSON.parse(localStorage.getItem("user"));
      let res = await authService.refreshToken(user.refresh_token);

      if (res.data?.token) {
        store.dispatch(
          "common/setFlashMsg",
          "Something went wrong please try again later"
        );
      } else {
        store.dispatch("auth/logout");
      }
    }
    return Promise.reject(error);
  }
);
