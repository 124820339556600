import noCacheHeader from "./no-cache-header";

/**
 * Return the authorization header
 * @param {String} token - if provided then use it, instead of fetching from local storage
 * @returns
 */
export default function authHeader(token = null) {
  let user = JSON.parse(localStorage.getItem("user"));

  if (token || (user && user.token)) {
    // return { Authorization: "Bearer " + user.token };
    // for back-end
    return {
      //combine with no cache headers so we dont have to add this in each GET call... //IE11 fix
      ...noCacheHeader(),
      authorization: "Bearer " + (token || user.token),
    }; //{ "x-access-token": user.token }
  } else {
    return {};
  }
}
