import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VeeValidate from "vee-validate";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import authMixin from "./plugins/auth.plugin";
import { loadFilters } from "./filters";
import "./assets/css/main.scss";
//Quill editor CSS 
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

library.add(faUserSecret);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.use(loadFilters);
Vue.use(VeeValidate);
// //to customize error messages
// const dict = {
//   custom: {
//     email: {
//       required: 'Your email is empty'
//     },
//     name: {
//       required: () => 'Your name is empty'
//     }
//   }
// };
// Validator.localize('en', dict);
// // or use the instance method
// this.$validator.localize('en', dict);

export const bus = new Vue();

Vue.use(authMixin);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
