<template>
  <v-navigation-drawer
    absolute
    :mini-variant.sync="mini"
    app
    permanent
    :style="contentBgColor"
    style="height: 200%; overflow-x: hidden"
    v-if="currentUser"
    v-model="drawer"
    @transitionend="isSubMenu = false"
  >
    <template
      v-slot:prepend
      v-if="
        currentUser.user_role_name == 'SUPER_ADMIN' ||
          currentUser.user_role_name == 'ADMIN'
      "
    >
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <v-img
              src="https://randomuser.me/api/portraits/women/85.jpg"
            ></v-img>
          </v-list-item-avatar>
        </v-list-item>

        <v-list-item link two-line>
          <v-list-item-content>
            <v-list-item-title> {{ currentUser.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ currentUser.email }}</v-list-item-subtitle>
          </v-list-item-content>
          <!-- <v-list-item-action>
              <v-icon>mdi-menu-down</v-icon>
            </v-list-item-action> -->
        </v-list-item>

        <v-list-item>
          <span>
            <router-link :to="{ path: '/profile' }"
              ><v-icon>mdi-face-profile</v-icon></router-link
            >
          </span>
          <span>
            <v-icon @click="logout" class="ml-2">mdi-logout</v-icon>
          </span>
        </v-list-item>
      </v-list>
    </template>

    <template v-slot:prepend v-else-if="isFranchisor">
      <v-img
        style="max-width: 300px; max-height: 115px;"
        :src="franchiseData.brand_logo"
      ></v-img>
    </template>

    <v-divider></v-divider>

    <v-list nav dense flat>
      <v-list-item
        v-for="item in filteredItems"
        :style="titleColor"
        link
        :to="item.url"
        :key="item.id"
      >
        <v-list-item-icon>
          <v-icon :style="titleColor"> {{ item.icon }}</v-icon>
        </v-list-item-icon>
        <template v-if="item.menu">
          <v-list-group class="ml-n2" v-model="isSubMenu[item.text]">
            <template v-slot:activator>
              <v-list-item-title
                :class="item.menu ? 'mr-n5' : 'mr-10'"
                :style="titleColor"
                >{{ item.text }}</v-list-item-title
              >
            </template>

            <v-list-item
              :style="titleColor"
              v-for="i in item.menu"
              :to="i.url"
              :key="i.id"
              :exact-path="true"
            >
              <v-list-item-icon class="mr-3" :style="titleColor">
                <v-icon :style="titleColor">{{ i.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title :style="titleColor">{{
                i.text
              }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </template>
        <v-list-item-title v-else>{{ item.text }}</v-list-item-title>
      </v-list-item>
    </v-list>

    <template v-slot:append v-if="isFranchisor">
      <v-img
        width="70%"
        class="ma-8"
        style="opacity:0.4"
        src="@/assets/images/NFIB-CMYK-80h.png"
      ></v-img>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { bus } from "@/main.js";
import FranchiseMixin from "@/mixins/franchise-style";
import BaseService from "@/services/base.service.js";

export default {
  name: "Sidebar",
  mixins: [FranchiseMixin],
  data() {
    return {
      items: [
        {
          id: 1,
          text: "Dashboard",
          value: "dashboard",
          url: "/user",
          icon: "mdi-desktop-mac-dashboard",
        },
        {
          id: 2,
          text: "Template Manager",
          value: "template",
          url: "/templates/list",
          icon: "mdi-file-document",
        },
        {
          id: 3,
          text: "Cluster Group Manager",
          value: "cluster-data",
          url: "#",
          icon: "mdi-account-multiple",
          menu: [
            {
              id: 1,
              text: "Franchise Manager",
              url: "/franchise/list/franchise",
              icon: "mdi-account-group",
            },
            {
              id: 2,
              text: "Scheme Manager",
              url: "/franchise/list/scheme",
              icon: "mdi-vector-triangle",
            },
          ],
        },
        {
          id: 4,
          text: "Client Manager",
          value: "franchisee",
          url: "/franchisees/list",
          icon: "mdi-account",
        },
        {
          id: 5,
          text: "Insurer Manager",
          value: "insurance",
          url: "/insurers/list",
          icon: "mdi-umbrella",
        },
        {
          id: 6,
          text: "Broker Manager",
          value: "broker",
          url: "/brokers/list",
          icon: "mdi-handshake",
        },
        {
          id: 7,
          text: "User Manager",
          url: "/users/list",
          icon: "mdi-badge-account-horizontal",
        },
        {
          id: 8,
          text: "Master Data Manager",
          value: "master-data",
          url: "#",
          icon: "mdi-database",
          menu: [
            { id: 1, text: "Section", url: "#", icon: "mdi-selection" },
            {
              id: 2,
              text: "Sum",
              url: "/master-data/sum/list",
              icon: "mdi-plus-box",
            },
            { id: 3, text: "Country", url: "#", icon: "mdi-map" },
            { id: 4, text: "State", url: "#", icon: "mdi-map-marker" },
            { id: 5, text: "City", url: "#", icon: "mdi-home" },
            {
              id: 6,
              text: "Product Docs",
              url: "/master-data/product-docs",
              icon: "mdi-file-document",
            },
          ],
        },
        {
          id: 9,
          text: "Policies",
          value: "policies",
          url: "/policies/list/all",
          icon: "mdi-file-document",
          menu: [
            {
              id: 1,
              text: "All",
              url: "/policies/list/all",
              icon: "mdi-select-group",
            },
            {
              id: 2,
              text: "Active",
              url: "/policies/list/active",
              icon: "mdi-plus-box",
            },
            {
              id: 3,
              text: "Expired",
              url: "/policies/list/expired",
              icon: "mdi-map",
            },
            {
              id: 4,
              text: "Pending",
              url: "/policies/list/pending_confirmation",
              icon: "mdi-selection",
            },
            {
              id: 5,
              text: "New",
              url: "/policies/list/new",
              icon: "mdi-plus-box-multiple-outline",
            },
          ],
        },
        {
          id: 10,
          text: "Customise Insurance",
          value: "customise-insurance",
          url: "/franchisee/customise-insurance/details",
          icon: "mdi-file-document",
          menu: [
            {
              id: 1,
              text: "1.Your Details",
              url: "/franchisee/customise-insurance/details",
              icon: "mdi-account",
            },
            {
              id: 2,
              text: "2.Insurance Coverage",
              url: "#",
              icon: "mdi-shield",
            },
          ],
        },
      ],
      filteredItems: [],
      drawer: true,
      mini: false,
      isSubMenu: {},
      menuPolicyUrls: {
        2: "/franchisee/customise-insurance/insurance-coverage",
      },
      isPolicyVisible: false,
    };
  },
  async mounted() {
    bus.$on(`toggleSideMenu`, (val) => {
      this.drawer = val;
      this.mini = val;
    });

    bus.$on("activate_sideMenu", (val) => {
      let index = this.filteredItems.findIndex(
        (item) => item.value === "customise-insurance"
      );

      this.filteredItems[index].menu[val - 1].url = this.menuPolicyUrls[val];
    });

    await this.getPolicies();

    this.filteredOptionsAsPerRole();
  },
  watch: {
    "currentUser.user_role_name"() {
      this.filteredOptionsAsPerRole();
    },
  },
  methods: {
    async getPolicies() {
      let service = new BaseService("policies");
      this.isPolicyVisible = false;

      if (this.currentUser && this.currentUser.user_role_name === "FRANCHISEE") {
        try {
          let res = await service.get_all({
            policy_status: 1,
            limit: 10,
            start: 1,
          });

          if (res.length > 0) {
            this.isPolicyVisible = true;
          }
        } catch (e) {
          // nothing here
        }
      } else {
        this.isPolicyVisible = true;
      }
    },
    logout() {
      // this.$store.commit("RESET_ALL_MODULES")
      this.$store.dispatch("auth/logout");
      this.$router.go();
    },
    filteredOptionsAsPerRole() {
      if (this.currentUser) {
        switch (this.currentUser.user_role_name) {
          case "FRANCHISE":
            this.filteredItems = this.items.filter((item) => {
              return (
                item.value == "dashboard" ||
                item.value == "franchisee" ||
                item.value == "policies"
              );
            });
            break;
          case "FRANCHISEE":
            this.isSubMenu = { "Customise Insurance": true };
            this.filteredItems = this.items.filter((item) => {
              return (
                // item.value == "dashboard" ||
                item.value == "customise-insurance" ||
                (this.isPolicyVisible && item.value == "policies")
              );
            });
            break;
          default:
            this.filteredItems = this.items.filter(
              (item) => item.value !== "customise-insurance"
            );
            break;
        }
      }
    },
  },
};
</script>
<style scoped>
.no-link {
  color: rgba(0, 0, 0, 0.8) !important;
}
</style>
