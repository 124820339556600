const initialState = { 
    franchiseData:{},
}

export const franchise = {
  namespaced: true,
  state: initialState,
  mutations: {
    setFranchiseData(state,franchise) {
      state.franchiseData = franchise
    }
  },
  
  getters: { 
    getFranchiseData(state) { 
      return state.franchiseData
    }
  }
};
