const initialState = {
  policyDetails: {},
  isPaymentComplete: false,
};

export const policies = {
  namespaced: true,

  state: initialState,

  mutations: {
    setPolicyDetails(state, policyDetails) {
      state.policyDetails = policyDetails;
    },
    setIsPaymentComplete(state, isPaymentComplete) {
      state.isPaymentComplete = isPaymentComplete;
    },
  },

  getters: {
    getPolicyDetails(state) {
      return state.policyDetails;
    },
    getIsPaymentComplete(state) {
      return state.isPaymentComplete;
    },
  },
};
