const authMixin = {
    methods: {
        async logout_if_login_not_valid() {
            await this.$store.dispatch('auth/isLoginValid');
        }
    }
}
    
    
export default {
    install (Vue /* , options */ ) {
        Vue.mixin(authMixin)
      }
};