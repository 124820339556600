<template>
  <div id="app">
    <v-app id="inspire">
      <template v-if="currentUser">
        <FranchisorTitleBar
          v-if="
            currentUser.user_role_name === 'FRANCHISE' ||
              currentUser.user_role_name === 'FRANCHISEE'
          "
          :currentUser="currentUser"
        />
        <Topbar v-else />
      </template>
      <template v-else>
        <Topbar />
      </template>

      <Sidebar :currentUser="currentUser" />

      <v-main>
        <v-container fluid>
          <v-row class="mt-10 no-gutters">
            <v-col>
              <router-view />
            </v-col>
          </v-row>

          <div>
            <v-snackbar v-model="snackbar" :timeout="snackbar_timeout">
              {{ snackbar_msg }}
              <template v-slot:action="{ attrs }">
                <v-btn
                  color="blue"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  Close
                </v-btn>
              </template>
            </v-snackbar>
          </div>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import Sidebar from "./components/layout/Sidebar";
import Topbar from "./components/layout/Topbar";
import FranchisorTitleBar from "@/components/franchise/FranchisorTitlebar";

export default {
  name: "App",

  data: () => ({
    drawer: null,

    snackbar: false,
    snackbar_timeout: 3000,
    snackbar_msg: "",
  }),

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes("ROLE_ADMIN");
      }

      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes("ROLE_MODERATOR");
      }

      return false;
    },
    is_login_page() {
      return this.$route.name === "Login";
    },

    is_change_pwd_page() {
      return this.$route.name === "password-reset";
    },
  },

  mounted() {
    this.logout_if_login_not_valid();
  },

  created() {
    const publicPages = ["/login", "/about", "/signup", "/forgot-password"];

    const authRequired = !publicPages.includes(this.$route.path);

    this.unsubscribe_to_store = this.$store.subscribe((mutation, state) => {
      if (
        mutation.type === "auth/logout" &&
        authRequired &&
        !this.is_change_pwd_page
      ) {
        this.$router.push("/login");
      }
      if (mutation.type === "common/flashMsg") {
        if (state.common.flash.msg) {
          this.snackbar_msg = state.common.flash.msg;
          this.snackbar = true;
        }
      }
    });
  },

  beforeDestroy() {
    this.unsubscribe_to_store();
  },

  components: { Topbar, Sidebar, FranchisorTitleBar },
};
</script>
