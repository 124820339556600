import jwt_decode from "jwt-decode";
import AuthService from "../services/auth.service";

const user = JSON.parse(localStorage.getItem("user"));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

export const auth = {
  namespaced: true,

  state: initialState,

  getters: {
    isLoggedIn: (state) => {
      return state.status.loggedIn;
    },
    userRole: (state) => {
      return state.user?.user_role_name;
    },
  },

  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        (user) => {
          commit("loginSuccess", user);
          return Promise.resolve(user);
        },
        (error) => {
          commit("loginFailure");
          return Promise.reject(error);
        }
      );
    },
    resetPassword(_, user) {
      return AuthService.resetPassword(user).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    changePassword(_, { password, token }) {
      return AuthService.changePassword(token, password).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit("logout");
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        (response) => {
          commit("registerSuccess");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("registerFailure");
          return Promise.reject(error);
        }
      );
    },
    refreshToken({ commit, dispatch }, user) {
      return AuthService.refreshToken(user.refresh_token).then(
        (response) => {
          commit("refreshToken", response.data.token);
          return true;
        },
        () => {
          dispatch("logout");
          return false;
        }
      );
    },
    isLoginValid({ dispatch }) {
      const user = JSON.parse(localStorage.getItem("user"));
      if (!user || !user.token) {
        dispatch("logout");
        return false;
      }
      const jwtPayload = jwt_decode(user.token);
      let isValid = jwtPayload.exp && jwtPayload.exp > Date.now() / 1000;

      if (jwtPayload.exp && jwtPayload.exp - 3600 < Date.now() / 1000) {
        return dispatch("refreshToken", user);
      }

      if (isValid) {
        return true;
      }
    },
  },

  mutations: {
    updateUser(state, user) {
      state.user = user;
    },
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    },
    refreshToken(state, token) {
      state.user.token = token;
    },
  },
};
