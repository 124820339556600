export default{
  name:'',
  computed: { 
    franchiseData() { 
      return this.$store.getters[`franchise/getFranchiseData`]
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    isFranchisor() { 
     if(this.currentUser && 
      (this.currentUser.user_role_name == 'FRANCHISE' || this.currentUser.user_role_name == 'FRANCHISEE' )) {
        return true
      } else return false
    },
    titleColor() {
      if(this.isFranchisor) {
          return { color : this.franchiseData.brand_text_color}
      } else return { color : 'rgba(0,0,0,0.8)'} 
    },
    contentBgColor() {
      if(this.isFranchisor) { 
        return { background : this.franchiseData.brand_background_color}
      } else return { color : 'rgba(0,0,0,0.8)'} 
    },
  }
}