const initialState = { flash: { msg: '' } }

export const common = {
  namespaced: true,

  state: initialState,

  actions: {
    setFlashMsg({ commit }, msg) {
          commit("flashMsg", msg);
          return Promise.resolve(msg);
    },
  },

  mutations: {
    flashMsg(state, msg) {
      state.flash.msg = msg;
    },
  },
};
