<template>
  <v-card>
    <v-app-bar absolute color="#0066cc" height="80" dark hide-on-scroll app>
      <v-app-bar-nav-icon
        v-if="currentUser"
        class="mr-5"
        @click="toggleSideMenu"
      ></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon class="mr-12">
        <v-img src="@/assets/images/NFIB-CMYK-80h.png" class="white--text" />
      </v-app-bar-nav-icon>
    </v-app-bar>
  </v-card>
</template>

<script>
import { bus } from "@/main.js";

export default {
  name: "App",
  data() {
    return {
      drawer: false,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    toggleSideMenu() {
      this.drawer = !this.drawer;
      bus.$emit(`toggleSideMenu`, this.drawer);
    },
  },
};
</script>

<style></style>
