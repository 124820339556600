import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import ResetPassword from "../views/ResetPassword.vue";
import jwt_decode from "jwt-decode";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  { path: "/login", name: "Login", component: Login },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/password/reset/:token",
    name: "password-reset",
    component: ResetPassword,
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("../views/Profile.vue"), // lazy-loaded
  },
  {
    path: "/admin",
    name: "admin",
    component: () => import("../views/BoardAdmin.vue"), // lazy-loaded
  },
  {
    path: "/mod",
    name: "moderator",
    component: () => import("../views/BoardFranchisee.vue"), // lazy-loaded
  },
  {
    path: "/user",
    name: "user",
    component: () => import("../views/BoardUser.vue"), // lazy-loaded
  },
  {
    path: "/templates/list",
    name: "templates-list",
    component: () =>
      import(
        /* webpackChunkName: "templates" */ "../components/template/TemplateList.vue"
      ),
  },
  {
    path: "/templates/edit/:id",
    name: "templates-edit",
    component: () =>
      import(
        /* webpackChunkName: "templates" */ "../components/template/TemplateEdit.vue"
      ),
    props: (route) => ({
      // crud_state: crud_state.EDIT,
      id: route.params.id,
    }),
  },
  {
    path: "/templates/add",
    name: "templates-add",
    component: () =>
      import(
        /* webpackChunkName: "templates" */ "../components/template/TemplateEdit.vue"
      ),
    props: {
      // crud_state: crud_state.ADD,
      id: null,
    },
  },
  {
    path: "/insurers/list",
    name: "insurers-list",
    component: () =>
      import(
        /* webpackChunkName: "brokers" */ "../components/broker/BrokerList.vue"
      ),
    props: { type_id: 1 },
  },
  {
    path: "/brokers/list",
    name: "brokers-list",
    component: () =>
      import(
        /* webpackChunkName: "brokers" */ "../components/broker/BrokerList.vue"
      ),
    props: { type_id: 2 },
  },
  {
    path: "/franchise/list/:type",
    name: "franchises-list",
    component: () =>
      import(
        /* webpackChunkName: "franchise-list" */ "../components/franchise/FranchiseList.vue"
      ),
    props: (route) => ({
      // crud_state: crud_state.EDIT,
      type: route.params.type,
      type_id: 3,
    }),
  },
  {
    path: "/franchise/add/:type",
    name: "franchise-add",
    component: () =>
      import(
        /* webpackChunkName: "franchise-add" */ "../components/franchise/FranchiseEdit.vue"
      ),
    props: (route) => ({
      // crud_state: crud_state.EDIT,
      id: null,
      type: route.params.type,
      type_id: 3,
    }),
  },
  {
    path: "/franchise/edit/:type/:id",
    name: "franchise-edit",
    component: () =>
      import(
        /* webpackChunkName: "franchise-edit" */ "../components/franchise/FranchiseEdit.vue"
      ),
    props: (route) => ({
      // crud_state: crud_state.EDIT,
      id: route.params.id,
      type: route.params.type,
    }),
  },
  {
    path: "/franchisees/list",
    name: "franchisees-list",
    component: () =>
      import(
        /* webpackChunkName: "franchise" */ "../components/franchisees/FranchiseesList.vue"
      ),
  },
  {
    path: "/franchisees/add",
    name: "franchisees-add",
    component: () =>
      import(
        /* webpackChunkName: "franchise" */ "../components/franchisees/FranchiseesEdit.vue"
      ),
    props: {
      // crud_state: crud_state.ADD,
      id: null,
    },
  },
  {
    path: "/franchisees/edit/:id",
    name: "franchisees-edit",
    component: () =>
      import(
        /* webpackChunkName: "franchise" */ "../components/franchisees/FranchiseesEdit.vue"
      ),
    props: (route) => ({
      // crud_state: crud_state.EDIT,
      id: route.params.id,
    }),
  },
  {
    path: "/users/list",
    name: "users-list",
    component: () =>
      import(
        /* webpackChunkName: "franchise" */ "../components/users/UsersList.vue"
      ),
  },
  {
    path: "/users/add",
    name: "users-add",
    component: () =>
      import(
        /* webpackChunkName: "franchise" */ "../components/users/UsersEdit.vue"
      ),
    props: {
      // crud_state: crud_state.ADD,
      id: null,
    },
  },
  {
    path: "/users/edit/:id",
    name: "users-edit",
    component: () =>
      import(
        /* webpackChunkName: "franchise" */ "../components/users/UsersEdit.vue"
      ),
    props: (route) => ({
      // crud_state: crud_state.EDIT,
      id: route.params.id,
    }),
  },
  {
    path: "/policies/list/:filterType",
    name: "policies-list",
    component: () =>
      import(
        /* webpackChunkName: "franchise" */ "../components/policies/PoliciesList.vue"
      ),
    props: (route) => ({
      // crud_state: crud_state.EDIT,
      filterType: route.params.filterType,
    }),
  },
  {
    path: "/master-data/sum/list",
    name: "sum-list",
    component: () =>
      import(
        /* webpackChunkName: "sums-list" */ "../components/master-data/sum/SumList.vue"
      ),
  },
  {
    path: "/master-data/sum/add",
    name: "sum-add",
    component: () =>
      import(
        /* webpackChunkName: "sums-add" */ "../components/master-data/sum/SumEdit.vue"
      ),
  },
  {
    path: "/master-data/sum/edit/:id",
    name: "sum-edit",
    component: () =>
      import(
        /* webpackChunkName: "sums-edit" */ "../components/master-data/sum/SumEdit.vue"
      ),
    props: (route) => ({
      id: route.params.id,
    }),
  },
  {
    path: "/master-data/product-docs",
    name: "product-docs",
    component: () =>
      import(
        /* webpackChunkName: "sums-list" */ "../components/master-data/product-docs/ProductDocList.vue"
      ),
  },
  {
    path: "/master-data/product-docs/add",
    name: "product-docs-add",
    component: () =>
      import(
        /* webpackChunkName: "sums-list" */ "../components/master-data/product-docs/ProductDocAdd.vue"
      ),
  },
  {
    path: "/master-data/product-docs/edit/:id",
    name: "product-docs-edit",
    component: () =>
      import(
        /* webpackChunkName: "sums-edit" */ "../components/master-data/product-docs/ProductDocAdd.vue"
      ),
    props: (route) => ({
      id: route.params.id,
    }),
  },
  {
    path: "/profile/edit",
    name: "profile-edit",
    component: () => import("../components/profile/ProfileEdit.vue"), // lazy-loaded
  },
  {
    path: "/franchisee/customise-insurance/details",
    name: "details",
    component: () =>
      import("../components/franchisees/insurance-coverage/YourDetails.vue"), // lazy-loaded
  },
  {
    path: "/franchisee/customise-insurance/insurance-coverage",
    name: "insurance-coverage",
    component: () =>
      import(
        "../components/franchisees/insurance-coverage/InsuranceCoverage.vue"
      ), // lazy-loaded
  },
  {
    path: "/franchisee/customise-insurance/preview-policy",
    name: "preview-policy",
    component: () =>
      import("../components/franchisees/insurance-coverage/PreviewPolicy.vue"), // lazy-loaded
  },
  {
    path: "/franchisee/customise-insurance/thank-you",
    name: "thank-you",
    component: () =>
      import(
        "../components/franchisees/insurance-coverage/CoverageThankYou.vue"
      ), // lazy-loaded
  },
  {
    path: "/payment-pf/status/:status",
    name: "payment-success",
    component: () => import("../components/payment/PFPaymentSuccess.vue"), // lazy-loaded
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const publicPages = [
    "/login",
    "/about",
    "/signup",
    "/forgot-password",
    "/password/reset",
  ];

  const authRequired = !publicPages.some((path) => to.path.startsWith(path));

  if (authRequired) {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user || !user?.token) {
      next("/login");
      return;
    }

    const jwtPayload = jwt_decode(user.token);
    let isValid = jwtPayload.exp && jwtPayload.exp > Date.now() / 1000;

    if (jwtPayload.exp && jwtPayload.exp - 3600 < Date.now() / 1000) {
      isValid = await store.dispatch("auth/refreshToken", user);
    }

    if (isValid) {
      if (to.path === "/user") {
        if (store.getters["auth/userRole"] === "SUPER_ADMIN") {
          next("/admin");
        } else if (store.getters["auth/userRole"] === "FRANCHISEE") {
          next("/mod");
        }
      }

      next();
    }
  }

  next();
});

export default router;
