<template>
  <div>
    <h1 class="page-title">
      Forgot Password
    </h1>

    <div class="card card-container login-card top-margin-card">
      <form name="form" @submit.prevent="handlePasswordReset">
        <div class="form-group">
          <label for="email">Email</label>
          <v-text-field
            v-model="user.email"
            v-validate="'required'"
            type="text"
            class="form-control"
            name="email"
            :disabled="resetSuccess"
          ></v-text-field>
          <div
            v-if="errors.has('email')"
            class="alert alert-danger"
            role="alert"
          >
            Email is required
          </div>
        </div>

        <div class="form-group" v-if="!resetSuccess">
          <v-btn elevation-2 type="submit">
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span>Reset Password</span>
          </v-btn>
        </div>

        <div class="form-group">
          <div v-if="message" class="alert alert-danger" role="alert">
            {{ message }}
          </div>
        </div>
        <br />
        <div class="form-group">
          <v-btn @click="$router.push('/login')">{{
            resetSuccess ? "Go to Login Page" : "Cancel"
          }}</v-btn>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import User from "../models/user";

export default {
  name: "ForgotPassword",
  data() {
    return {
      user: new User("", ""),
      loading: false,
      resetSuccess: false,
      message: "",
    };
  },
  methods: {
    resetPassword() {
      if (this.user.email) {
        this.$store.dispatch("auth/resetPassword", this.user).then(
          () => {
            this.resetSuccess = true;
            this.loading = true;
            this.message =
              "A link to reset you password has been sent to the above mail id.";
          },
          (error) => {
            this.loading = false;
            this.message =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          }
        );
      }
    },
    handlePasswordReset() {
      this.loading = true;
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }
        this.resetPassword();
      });
    },
  },
};
</script>

<style scoped>
label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 0px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
</style>
