<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="600"
      height="auto"
      @click:outside="toggleDialog"
    >
      <v-card style="overflow:hidden;">
        <v-card-title :style="[contentBgColor, titleColor]">
          <v-icon class="mr-1" :style="titleColor" medium
            >mdi-information</v-icon
          >
          Franchise Details
        </v-card-title>
        <v-divider class="mb-5"></v-divider>
        <v-content class="pa-5" v-if="franchiseData">
          <h3 class="mb-2">{{ franchiseData.name }}</h3>
          <v-chip
            ><v-icon class="mr-2">mdi-ticket</v-icon>
            {{ franchiseData.business_activity }}</v-chip
          >
          <div class="space-20"></div>
          <h4><v-icon>mdi-map-marker</v-icon> Address</h4>
          <v-divider class="my-2"></v-divider>
          <div v-if="franchiseData.address">
            <p><b>Name</b> : {{ franchiseData.address.name }}</p>
            <p>
              <b> Street :</b> {{ franchiseData.address.address1 }},
              {{ franchiseData.address.address2 }}
            </p>
            <p
              v-if="
                franchiseData.address.city_name ||
                  franchiseData.address.state.name
              "
            >
              <b>Location:</b>
              {{ franchiseData.address.city_name }},
              {{ franchiseData.address.state.name }},
              {{ franchiseData.address.country.name }}
            </p>

            <p v-if="franchiseData.address.country.name"></p>
          </div>
        </v-content>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FranchiseMixin from "@/mixins/franchise-style";

export default {
  name: "FranchiseDetails",
  props: {
    dialog: Boolean,
  },
  mixins: [FranchiseMixin],
  methods: {
    toggleDialog() {
      this.$emit(`closeDialog`);
    },
  },
};
</script>

<style lang="scss" scoped></style>
